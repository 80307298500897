import ExpandLess from '@mui/icons-material/ExpandLess';
import { Box, Typography, useTheme } from '@mui/material';
import React from 'react';
import { IconWave } from '../../assets/icons/how-it-works/wave';
import { LL_BACKGROUND_COLOR } from '../../colors';
import { BackButton } from '../../components/back-button';
import { DecorativeHeading } from '../../components/decorative-heading';
import CareerForm from '../../components/forms/career-form';
import Layout from '../../components/layout';
import { Absolute } from '../../components/primitives/absolute';

import { JobCardProps } from '../../components/careers/job-card';
import { graphql } from 'gatsby';
import { PositionsQueryQuery } from '../../../graphql-types';


export interface JobDescriptionProps {
  data: PositionsQueryQuery
}

const aboutus = `ListingLoop.com.au is an Australia-wide real estate marketplace that connects buyers, sellers and agents so that everyone wins. We’re different to other real estate websites as we focus on pre-market and off-market property listings, and we’re powered by smart tech that’s unique to the real estate market. ListingLoop.com.au shakes up the traditional way that real estate is done by providing a more tailored VIP experience that’s curated specifically for each individual user.

In the few years we’ve been around, we’re proud to be called Australia’s leading marketplace for pre-market and off-market properties. Leading agents list their properties for sale on ListingLoop.com.au first and buyers get to jump the queue before the masses.

The ListingLoop.com.au company culture is built around giving our team the opportunity to excel at what they do best, challenge the status quo and make a tangible impact on our business as we grow. We enjoy each other’s company and inject a healthy dose of fun into our work environment. We’re passionate and relentless about achieving (and exceeding) our goals.

We’re expanding fast across Australia with thousands of agents across the country using our technology to grow their businesses and stay ahead of the pack.

The Listing Loop Group includes ListingLoop.com.au and LendingLoop.com.au.`;

const opening = {
  jobTitle: 'React JS Developer',
  department: 'Digital Solutions',
  location: 'Mornington',
  aboutRole: `The front-end JS software developer role involves implementing and maintaining exciting new features across the various ListingLoop.com.au web properties. While we operate in the real estate space, we’re a tech company and projects are thought through from the perspective of what gives our users the best experience possible. We are seeking a developer that can bring new product ideas and features to our business.

You will work alongside and report to our digital solutions manager to implement new features and solve issues as they arise. In addition, you will be supported by an external development team that implements specific tasks and overflow.

You will be incredibly passionate about your field and keep ahead of the curve with all relevant industry knowledge, new frameworks and new technologies as they are made available.`,
  responsibilities: [
    'Develop and deploy new components and features across Listing Loop’s various web front-ends.'
  ]
};

const JobDescription = (props: JobDescriptionProps) => {
const resultData = props.data.craftGqlPositionsDefaultEntry
console.log(resultData,'##')

  const theme = useTheme();
  const { jobTitle, department, location, responsibilities, aboutRole } = opening;
  return (
    <Box>
      <Box
        sx={{
          paddingY: '100px',
          paddingTop: '120px',
          paddingX: '20px',
          width: '60%',
          maxWidth: '1300px',
          marginX: 'auto',
          [theme.breakpoints.down('md')]: {
            paddingTop: '100px',
            width: '100%'
          }
        }}
      >
        <Box pb={{ xs: '40px', md: '70px' }}>
          <BackButton href="http://localhost:8000/careers" />
        </Box>
        <Typography color="primary.main" fontFamily="Tropiline" variant="h2">
          {resultData.title}
        </Typography>
        <Box my="15px">
          <Typography
            variant="subtitle2"
            fontWeight={400}
          >{`Department: ${resultData.positionDepartment}`}</Typography>
          <Typography variant="subtitle2" fontWeight={400}>{`Location: ${resultData.positionLocation}`}</Typography>
        </Box>
        <Typography py="30px" fontSize={{ xs: '22px', md: '28px' }} color="primary.main">
          Front-end React JS Developer for fast growing tech company. Join a team of fun and
          passionate go-getters.
        </Typography>
        <Box pb={'40px'}>
          <Typography variant="h5" py="10px" fontWeight={500}>
            About us
          </Typography>
          <Typography variant="subtitle2" fontWeight={400}>
            <div
              style={{ fontSize: 'inherit' }}
              dangerouslySetInnerHTML={{ __html: aboutus }}
            ></div>
          </Typography>
        </Box>
        <Box pb={'40px'}>
          <Typography variant="h5" py="10px" fontWeight={500}>
            About the role:
          </Typography>
          <Typography variant="subtitle2" fontWeight={400}>
            <div
              style={{ fontSize: 'inherit' }}
              dangerouslySetInnerHTML={{ __html: aboutRole }}
            ></div>
          </Typography>
        </Box>
        <Box pb={'40px'}>
          <Typography variant="h5" py="10px" fontWeight={500}>
            Responsibilities:
          </Typography>
          {responsibilities.map((value, index) => (
            <Box display="flex" key={index}>
              <ExpandLess
                sx={{
                  color: theme.palette.primary.main,
                  transform: 'rotate(90deg)',
                  height: '16px',
                  width: '16px',
                  marginRight: '10px',
                  marginTop: '6px'
                }}
              />
              <Typography variant="subtitle2" fontWeight={400}>
                {value}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
      <IconWave width="100%" height="auto" marginBottom="-5px" />
      <Box bgcolor={LL_BACKGROUND_COLOR}>
        <DecorativeHeading text="Want to be a part of our team?" />
        <Box
          sx={{
            paddingY: '60px',
            paddingX: '20px',
            width: '60%',
            maxWidth: '1300px',
            marginX: 'auto',
            [theme.breakpoints.down('md')]: {
              width: '100%'
            }
          }}
        >
          <CareerForm theme={theme} />
        </Box>
        <Box bgcolor={LL_BACKGROUND_COLOR} width="100%">
          <Box position="relative">
            <Absolute
              height="250px"
              width="100%"
              zIndex={-1}
              bgcolor={LL_BACKGROUND_COLOR}
            ></Absolute>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};



export const jobsQuery = graphql`
query PositionsQuery($slug: String) {
  craftGqlPositionsDefaultEntry(slug: { eq: $slug }) {
    slug
        positionDepartment
        positionLocation
        richText
        title
    }
  }`


JobDescription.Layout = Layout;
JobDescription.isNavbarBackgroundEmpty = true;

export default JobDescription;
