import { Grid, Theme, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import isAlpha from 'validator/lib/isAlpha';
import isEmail from 'validator/lib/isEmail';
import isEmpty from 'validator/lib/isEmpty';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { CAPTIONS_TEXT_COLOR } from '../../colors';
import { isUrlPresent } from '../../utils/validators';
import { CustomTextArea } from '../inputs/text-area';
import { CustomInput } from '../inputs/text-field';
import { ErrorMessage } from '../primitives/error-message';
import { FlexBox } from '../primitives/flex';
import { PrimaryButton } from '../primitives/primary-button';

const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  position: '',
  currentPosition: '',
  file: null,
  coverLetter: ''
};

export interface CareerFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  position: string;
  currentPosition: string;
  file?: File | null;
  coverLetter?: string;
}

export interface CareerFormProps {
  theme: Theme;
}

export default function CareerForm(props: CareerFormProps) {
  const { theme } = props;
  const [formValues, setFormValues] = useState<CareerFormValues>(initialValues);
  const [errorValues, setErrorValues] = useState<CareerFormValues>(initialValues);
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { firstName, lastName, email, phone, position, currentPosition, file, coverLetter } =
    formValues;

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    let result = validateForm(formValues);
    const { isValidForm, errorValues } = result;
    if (!isValidForm) {
      setErrorValues(errorValues);
    } else {
      console.log('Valid');
    }
    setIsSubmitting(false);
  };

  const settingFormValue = (obj: { value: string; field: string }) => {
    const { value, field } = obj;
    setFormValues((formValues) => ({ ...formValues, [field]: value }));
  };

  const validateForm = (formValues: CareerFormValues) => {
    let isValid = true;
    let error = { ...errorValues };
    Object.keys(formValues).map((key) => {
      let value = formValues[key];

      if (key != 'file' && key != 'coverLetter' && isEmpty(value)) {
        error[key] = '*Mandatory field';
        isValid = false;
      } else if (isUrlPresent(value)) {
        error[key] = '*URL not allowed';
        isValid = false;
      } else {
        error[key] = '';
      }
    });
    console.log({ isValidForm: isValid, errorValues: error });
    if (!isValid) {
      setIsValidForm(isValid);
      return { isValidForm: isValid, errorValues: error };
    }

    Object.keys(formValues).map((key) => {
      let value = formValues[key];
      switch (key) {
        case 'firstName':
        case 'lastName':
        case 'position':
        case 'currentPosition':
          if (!isAlpha(value)) {
            error[key] = '*Only allowed alphabets';
            isValid = false;
          }
          break;
        case 'email':
          if (!isEmail(value)) {
            error.email = '*Invalid email';
            isValid = false;
          }
          break;
        case 'phone':
          if (!isMobilePhone(value, ['en-AU'])) {
            error.phone = '*Invalid phone number';
            isValid = false;
          }
          break;
      }
    });
    setIsValidForm(isValid);
    console.log({ isValidForm: isValid, errorValues: error });
    return { isValidForm: isValid, errorValues: error };
  };

  const handleChange = (obj: { value: string; field: string }) => {
    const { value, field } = obj;

    let error = { ...errorValues };
    validateForm({ ...formValues, [field]: value });
    if (field != 'coverLetter' && field != 'file' && isEmpty(value)) {
      error[field] = '*Mandatory field';
      settingFormValue(obj);
      setErrorValues(error);
      return;
    }
    if (isUrlPresent(value)) {
      error[field] = '*URL not allowed';
      settingFormValue(obj);
      setErrorValues(error);
      return;
    }
    error[field] = '';

    switch (field) {
      case 'firstName':
      case 'lastName':
      case 'position':
      case 'currentPosition':
        error[field] = !isAlpha(value) ? '*Only allowed alphabets' : '';
        break;
      case 'email':
        error.email = !isEmail(value) ? '*Invalid email' : '';
        break;
      case 'phone':
        error.phone = !isMobilePhone(value, ['en-AU']) ? '*Invalid phone number' : '';
        break;
    }

    setErrorValues(error);
    settingFormValue(obj);
  };

  const handleFileChange = (selectorFiles: FileList) => {
    console.log(selectorFiles);
    setFormValues((formValues) => ({ ...formValues, file: selectorFiles[0] }));
  };

  return (
    <form onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <CustomInput
            placeholder="First Name*"
            onChange={(e) => handleChange({ value: e.target.value, field: 'firstName' })}
            value={firstName}
            style={{ width: '100%' }}
          />
          <ErrorMessage padding={'10px 18px 0px'}>{errorValues.firstName}</ErrorMessage>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomInput
            placeholder="Last Name*"
            onChange={(e) => handleChange({ value: e.target.value, field: 'lastName' })}
            value={lastName}
            style={{ width: '100%' }}
          />

          <ErrorMessage padding={'10px 18px 0px'}>{errorValues.lastName}</ErrorMessage>
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomInput
            placeholder="Email*"
            value={email}
            onChange={(e) => handleChange({ value: e.target.value, field: 'email' })}
            style={{ width: '100%' }}
          />
          <ErrorMessage padding={'10px 18px 0px'}>{errorValues.email}</ErrorMessage>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomInput
            placeholder="Phone*"
            value={phone}
            onChange={(e) => handleChange({ value: e.target.value, field: 'phone' })}
            style={{ width: '100%' }}
          />
          <ErrorMessage padding={'10px 18px 0px'}>{errorValues.phone}</ErrorMessage>
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomInput
            placeholder="Position*"
            value={position}
            onChange={(e) => handleChange({ value: e.target.value, field: 'position' })}
            style={{ width: '100%' }}
          />
          <ErrorMessage padding={'10px 18px 0px'}>{errorValues.position}</ErrorMessage>
        </Grid>

        <Grid item xs={12} md={6}>
          <CustomInput
            placeholder="Current Position*"
            value={currentPosition}
            onChange={(e) => handleChange({ value: e.target.value, field: 'currentPosition' })}
            style={{ width: '100%' }}
          />
          <ErrorMessage padding={'10px 18px 0px'}>{errorValues.currentPosition}</ErrorMessage>
        </Grid>
        <Grid item xs={12} md={12}>
          <input
            type="file"
            id="actual-btn"
            hidden
            onChange={(e) => handleFileChange(e.target.files)}
          />
          <FlexBox flexDirection={{ xs: 'column', md: 'row' }}>
            <Box>
              <label
                htmlFor="actual-btn"
                style={{
                  display: 'inline-block',
                  border: `2px solid ${theme.palette.primary.main}`,
                  borderRadius: '50px',
                  color: theme.palette.secondary.main,
                  padding: '12px 28px',
                  cursor: 'pointer'
                }}
              >
                + Upload file
              </label>
            </Box>
            <div
              id="file-chosen"
              style={{
                marginLeft: '10px',
                paddingTop: '10px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}
            >
              {file?.name ?? 'No file chosen'}
            </div>
          </FlexBox>
          <ErrorMessage padding={'10px 18px 0px'}>{errorValues.file}</ErrorMessage>
        </Grid>
        <Grid item xs={12} md={12}>
          <CustomTextArea
            placeholder="Cover Letter"
            value={coverLetter}
            onChange={(e) => handleChange({ value: e.target.value, field: 'coverLetter' })}
            minRows={12}
            maxRows={13}
            style={{ width: '100%' }}
          />
          <ErrorMessage padding={'10px 18px 0px'}>{errorValues.coverLetter}</ErrorMessage>
        </Grid>
        <Grid item xs={12} md={12}>
          <Typography padding={'10px 18px 0px'} color={CAPTIONS_TEXT_COLOR}>
            *Mandatory
          </Typography>
        </Grid>
      </Grid>
      <Box textAlign="center">
        <PrimaryButton type="submit" variant="contained" disabled={!isValidForm || isSubmitting}>
          Submit
        </PrimaryButton>
      </Box>
    </form>
  );
}
